import createFetch from "utils/createFetch";
import { runFetchJson, runFetch } from "utils/runFetch";
import { apiResponseHandler } from "utils/apiResponseFilter";
import {
  EPPSAPI_PROTO_AND_DOMAIN,
  EPPSWIDGET_SERVER_API,
  EPPSAPI_VERSION,
  EPPSWIDGET_SERVER_VERSION,
} from "common/constants/AppGlobals";
import Common from "common/services/Common";
import moment from "moment-timezone";
import Session, { IS_LO, JWT_TOKEN } from "services/Session";
import { formatDate } from "services/helpers";

const widgetServerAPI = EPPSWIDGET_SERVER_API + EPPSWIDGET_SERVER_VERSION;
const eppsAPI = EPPSAPI_PROTO_AND_DOMAIN + EPPSAPI_VERSION;
const getApiUrl = () => (Session.get(IS_LO) ? widgetServerAPI : eppsAPI);

const getLoancatcherEnvTarget = () => {
  let env = EPPSAPI_PROTO_AND_DOMAIN.match(/https?:\/\/([^.]+)/)[1];

  if (EPPSAPI_PROTO_AND_DOMAIN.includes("localhost")) {
    env = "local";
  }

  return env;
};

const getLoancatcherAPIUrl = () => {
  const target = getLoancatcherEnvTarget();
  const strippedEnv = target.replace("aws", "");

  switch (strippedEnv) {
    case "dev":
      return `https://api.d2.lcdev.epps.rd.elliemae.io/api/v1`;
    case "qa":
      return `https://api.q1.lcqa.epps.rd.elliemae.io/api/v1`;
    case "int":
      return `https://api.i1.lcint.epps.rd.elliemae.io/api/v1`;
    case "stg":
      return `https://api.st1.lcstg.epps.ellielabs.com/api/v1`;
    case "prod":
      return `https://ppe.p1.lcprod.epps.ellieservices.com/api/v1`;
    case "uat":
      return `https://api.u1.lcuat.epps.uat.ice.com/api/v1`;
    default: {
      throw new Error(
        `Invalid environment target: ${target}. Please check the EPPSAPI_PROTO_AND_DOMAIN variable.`
      );
    }
  }
};

export default {
  getLockPageSettings: () =>
    runFetchJson(async () => {
      const originId = await Common.getOriginId();

      return createFetch(
        `${getApiUrl()}/encompassSettings/secondary/locks?OriginId=${originId}`,
        {
          headers: {
            "X-Elli-PAT": await Common.getPATToken(),
          },
        },
        {},
        {
          method: "GET",
        },
        [],
        false,
        true
      );
    }),

  calculateRateLockExtension: (opts) =>
    runFetchJson(async () => {
      const { loanUuid, lockDate, numberOfDays, parentLockUuid } = opts;
      const originId = await Common.getOriginId();

      return createFetch(
        `${getApiUrl()}/loan/${loanUuid}/calculators/rateLock?OriginId=${originId}`,
        {
          headers: {
            "X-Elli-PAT": await Common.getPATToken(),
          },
          body: JSON.stringify({
            lockRequestType: "Extension",
            lockDate: formatDate(new Date(lockDate), "ISODateOnly"),
            numberOfDays,
            parentLock: {
              entityId: parentLockUuid,
              EntityType: "RateLock",
            },
          }),
        },
        {},
        {
          method: "POST",
        },
        [],
        true,
        true
      );
    }),

  pollAdjustmentsApproval: async (opts) => {
    const response = await runFetchJson(async () => {
      const { loanNumber, groupUuid } = opts;
      return createFetch(
        `${getLoancatcherAPIUrl()}/ice-pps/loan/${loanNumber}/polling`,
        {
          headers: {
            "X-EPC-Transaction-Uuid": await Common.getOriginId(),
            "X-Elli-PAT": await Common.getPATToken(),
            "X-PPE-Env": getLoancatcherEnvTarget(),
          },
          body: JSON.stringify({
            groupUuid,
          }),
        },
        {},
        {
          method: "POST",
        },
        [],
        true,
        true
      );
    });

    const sorted = {
      // interface AdjustmentSubmissionStatus {
      // Requested
      // Approved
      // Denied
      // Removed
      // Submitted
      // Submission_Failed
      // }

      successful: (response?.item?.adjustments ?? []).filter(
        (adj) => adj.status === "Submitted"
      ),
      failed: (response?.item?.adjustments ?? []).filter(
        // Until we build concessions with full workflow, we can use simplified status logic here
        (adj) => adj.status !== "Submitted"
      ),
    };

    return sorted;
  },
  createAdjustments: async (opts) => {
    const adjustments = opts.adjustments ?? [];

    if (!adjustments?.length) {
      return null;
    }

    const response = await runFetchJson(async () => {
      return createFetch(
        `${getLoancatcherAPIUrl()}/ice-pps/loan/${
          opts.loanNumber
        }/adjustments/${opts.adjustmentType}`,
        {
          headers: {
            "X-EPC-Transaction-Uuid": await Common.getOriginId(),
            "X-Elli-PAT": await Common.getPATToken(),
            "X-PPE-Env": getLoancatcherEnvTarget(),
          },
          body: JSON.stringify(adjustments),
        },
        {},
        {
          method: "POST",
        },
        [],
        true,
        true
      );
    });
    return response?.item?.uuid;
  },
  deleteAdjustments: async (opts) => {
    const adjustments = opts.adjustments ?? [];
    if (!adjustments.length) {
      return;
    }

    await Promise.all(
      adjustments.map((i) =>
        runFetch(async () =>
          createFetch(
            `${getLoancatcherAPIUrl()}/ice-pps/adjustment/${i.uuid}`,
            {
              headers: {
                "X-EPC-Transaction-Uuid": await Common.getOriginId(),
                "X-Elli-PAT": await Common.getPATToken(),
                "X-PPE-Env": getLoancatcherEnvTarget(),
              },
            },
            {},
            {
              method: "DELETE",
            },
            [],
            true,
            true
          )
        )
      )
    );
  },

  getAdjustments: async (opts) => {
    const loanNumber = opts?.loanNumber;
    if (!loanNumber) {
      return [];
    }
    const response = await runFetchJson(async () => {
      return createFetch(
        `${getLoancatcherAPIUrl()}/query/drill-down-sources/adjustment`,
        {
          headers: {
            "X-EPC-Transaction-Uuid": await Common.getOriginId(),
            "X-Elli-PAT": await Common.getPATToken(),
            "X-PPE-Env": getLoancatcherEnvTarget(),
          },
          body: JSON.stringify({
            filters: {
              ppeLoanNumber: [loanNumber],
            },
            facets: {},
            dimensionSelectors: {},
            indexName: "ADJUSTMENT",
            dateKeyField: "createdDate",
            detailLevel: "KeyFactTuples",
            rows: -1,
          }),
        },
        {},
        {
          method: "POST",
        },
        [],
        true,
        true
      );
    });

    const results = response?.item?.detailResults ?? [];

    return results
      .filter((result) => result.status === "Submitted")
      .map((result) => ({
        uuid: result.adjustmentUuid,
        description: result.description,
        price: result.points,
        adjustmentType: result.adjustmentType,
        status: result.status,
        approvedBy: result.approvedBy,
        approvedReason: result.approvedReason,
        approvalDate: result.approvalDate
          ? formatDate(new Date(result.approvalDate), "Calendar")
          : null,
      }));
  },
  getTransactionStatus: async (transactionId) => {
    const response = await runFetchJson(async () =>
      createFetch(
        `${getLoancatcherAPIUrl()}/ice-pps/transactions/${transactionId}`,
        {
          headers: {
            "X-EPC-Transaction-Uuid": await Common.getOriginId(),
            "X-Elli-PAT": await Common.getPATToken(),
            "X-PPE-Env": getLoancatcherEnvTarget(),
          },
        },
        {},
        {
          method: "GET",
        },
        [],
        true,
        true
      )
    );

    return response?.item;
  },
  updateTransaction: async (opts) => {
    return runFetch(async () =>
      createFetch(
        `${getLoancatcherAPIUrl()}/ice-pps/transactions`,
        {
          headers: {
            "X-EPC-Transaction-Uuid": await Common.getOriginId(),
            "X-Elli-PAT": await Common.getPATToken(),
            "X-PPE-Env": getLoancatcherEnvTarget(),
          },
          body: JSON.stringify(opts),
        },
        {},
        { method: "POST" },
        [],
        true,
        true
      )
    );
  },
  getRates: (opts) =>
    createFetch(
      `${getApiUrl()}/loanQualifier`,
      opts,
      {},
      {
        method: "POST",
      },
      [apiResponseHandler(400, 401, 412)],
      true,
      true
    ),
  setUserEPPSMapping: (opts, username) =>
    createFetch(
      `${widgetServerAPI}/users/${username}`,
      opts,
      {},
      {
        method: "PUT",
      },
      [apiResponseHandler(404, 401, 409)],
      false,
      true
    ),
  login: (opts, username) =>
    createFetch(
      `${widgetServerAPI}/users/${username}`,
      opts,
      {},
      {
        method: "POST",
      },
      [apiResponseHandler(401)],
      false,
      true
    ),
  validateCredentials: (opts, username) =>
    createFetch(
      `${widgetServerAPI}/users/${username}`,
      opts,
      {},
      {
        method: "POST",
      },
      [apiResponseHandler(400, 401)],
      false,
      true
    ),
  refreshJWT: (opts, username) =>
    createFetch(
      `${widgetServerAPI}/users/${username}/refreshtoken`,
      opts,
      {},
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      false,
      true
    ),
  getUserRoles: (opts, tokens) =>
    createFetch(
      `${eppsAPI}/user/role/`,
      opts,
      tokens,
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getUserRolesV1: async () => {
    const token = await Common.getPATToken();
    return createFetch(
      `${getApiUrl()}/user/role`,
      {
        headers: {
          "X-Elli-PAT": token,
        },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: "POST",
      },
      [apiResponseHandler(401)],
      true,
      true
    );
  },
  getLockDeskHours: async (originId, loanChannelId) => {
    const token = await Common.getPATToken();
    return createFetch(
      `${getApiUrl()}/lockdesk/hours`,
      {
        headers: {
          "X-Elli-PAT": token,
        },
        body: JSON.stringify({
          originId,
          loanChannelId,
        }),
      },
      {},
      {
        method: "POST",
      },
      [apiResponseHandler(401)],
      true,
      true
    );
  },
  getLockDeskValidateStatus: async (validateData) =>
    createFetch(
      `${getApiUrl()}/lockdesk/validate/lockRequest`,
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
          ...validateData,
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  setOnrpAccruedAmount: async (loanChannelId, loanAmount) =>
    createFetch(
      `${getApiUrl()}/lockdesk/onrp/accrued-amount`,
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
          loanAmount,
          loanChannelId,
          rollback: false,
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  selectProgramRate: (opts, tokens) =>
    createFetch(
      `${getApiUrl()}/loans/${opts.loanId}/rateSelector`,
      opts,
      tokens,
      {
        method: "POST",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),

  // PartnerAPI/WidgetAPI Lookup APIs
  getWebhookLookups: async (lookupName) =>
    createFetch(
      `${widgetServerAPI}/lookups/`,
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify({
          lookupName,
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getLockSummaryDocument: async (payload) => {
    const originId = await Common.getOriginId();
    return createFetch(
      `${getApiUrl()}/documents/locksummary`,
      {
        body: JSON.stringify({
          originId,
          comments: payload.comments,
          rateSelectorResponse: payload.rateSelectorResponse,
        }),
      },
      {},
      { method: "POST" },
      [apiResponseHandler(401)],
      true,
      true
    );
  },

  getWebhookZipLocations: async (zip) =>
    createFetch(
      `${widgetServerAPI}/lookups/zipcodes/${zip}/locations`,
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),

  getWebhookStateCounties: async (stateId) =>
    createFetch(
      `${widgetServerAPI}/lookups/states/${stateId}/counties`,
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),

  // EPPS's Lookup APIS

  getZipLocations: (zip, opts, tokens) =>
    createFetch(
      `${eppsAPI}/lookups/zipcodes/${zip}/locations`,
      opts,
      tokens,
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),

  getStateCounties: (stateId, opts, tokens) =>
    createFetch(
      `${eppsAPI}/lookups/state/${stateId}/counties`,
      opts,
      tokens,
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),

  getAdjustmentDetails: (programId, opts, tokens) =>
    createFetch(
      `${eppsAPI}/loans/programs/${programId}/adjustments`,
      opts,
      tokens,
      {
        method: "POST",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),

  getProgramGuidelines: (programID, opts, tokens) =>
    createFetch(
      `${eppsAPI}/programs/${programID}/guidelines`,
      opts,
      tokens,
      { method: "GET" },
      [apiResponseHandler(401)],
      true,
      true
    ),

  // invalidate token for logout
  getOrigin: (opts, originId, source, type, lockId) => {
    let urlParameters = "";
    if (source) {
      urlParameters = `?SourceApplicationForm=${source}`;
      if (type) {
        urlParameters = `${urlParameters}&TransactionType=${type}`;
      }
      if (lockId) {
        urlParameters = `${urlParameters}&LockId=${lockId}`;
      }
    }
    return createFetch(
      `${widgetServerAPI}/origins/${originId}${urlParameters}`,
      opts,
      {},
      {
        method: "GET",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(400, 404, 401, 409)],
      false,
      true
    );
  },
  getUserDataV1: async () =>
    createFetch(
      `${getApiUrl()}/user`,
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getUserDataV2: (opts, tokens) =>
    createFetch(
      `${getApiUrl()}/user`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: "Bearer",
      },
      tokens,
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),

  getWebhookData: (opts, OriginId, action) =>
    createFetch(
      `${widgetServerAPI}/Webhooks/${OriginId}/${action}`,

      opts,
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(400, 401)],
      true,
      true
    ),
  getEpcTransaction: (opts, transactionId) =>
    createFetch(
      `${widgetServerAPI}/epc/transactions/${transactionId}/response/status`,

      opts,
      {},
      {
        method: "GET",
      },
      [apiResponseHandler(400, 401)],
      true,
      true
    ),
  getUpdatedfield: (opts, originId) =>
    createFetch(
      `${widgetServerAPI}/encompassFieldUpdates/${originId}`,
      opts,
      {},
      {
        method: "POST",
      },
      [apiResponseHandler(400, 401)],
      true,
      true
    ),
  getEpcWebhookData: (opts, transactionId, action) =>
    createFetch(
      `${widgetServerAPI}/Webhooks/${transactionId}/${action}`,
      opts,
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(400, 401)],
      true,
      true
    ),
  completeTransaction: (opts, transactionId) =>
    createFetch(
      `${widgetServerAPI}/response/${transactionId}`,
      opts,
      {},
      {
        method: "POST",
      },
      [apiResponseHandler()],
      false,
      true
    ),

  getGeocodesV1: (opts, loanId) =>
    createFetch(
      `${getApiUrl()}/lookups/loans/${loanId}/geocodes/`,
      {
        headers: { "X-Elli-PAT": opts.patToken },
        body: JSON.stringify({
          originId: opts.originId,
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getGeocodesV2: (opts, loanId, tokens) =>
    createFetch(
      `${getApiUrl()}/loans/${loanId}/geocodes/`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: "Bearer",
      },
      tokens,
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getProgramGuidelinesV1: (opts, programID) =>
    createFetch(
      `${getApiUrl()}/guidelines/${programID}`,
      {
        headers: { "X-Elli-PAT": opts.patToken },
        body: JSON.stringify({
          originId: opts.originId,
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getProgramGuidelinesV2: (opts, programID, tokens) =>
    createFetch(
      `${getApiUrl()}/programs/${programID}/guidelines`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: "Bearer",
      },
      tokens,
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getProgramAdjustedAndRawPricesV1: (opts, loanID) =>
    createFetch(
      `${getApiUrl()}/pricedata/${loanID}`,
      {
        body: JSON.stringify({
          originId: opts.originId,
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)]
    ),
  getProgramAdjustedAndRawPricesV2: (opts, loanID, tokens) =>
    createFetch(
      `${getApiUrl()}/${loanID}/pricedata`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: "Bearer",
      },
      tokens,
      {
        method: "POST",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getRateDocInstanceV1: (opts, DocumentId) =>
    createFetch(
      `${getApiUrl()}/ratesheet/Validate/${DocumentId}`,
      {
        headers: { "X-Elli-PAT": opts.patToken },
        body: JSON.stringify({
          originId: opts.originId,
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)]
    ),
  getRateDocInstanceV2: (opts, documentId, tokens) =>
    createFetch(
      `${getApiUrl()}/ratesheet/Validate/${documentId}`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: "Bearer",
      },
      tokens,
      {
        method: "GET",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getRateSheetDocumentV1: (opts, DocumentId) =>
    createFetch(
      `${getApiUrl()}/ratesheet/${DocumentId}`,
      {
        headers: { "X-Elli-PAT": opts.patToken },
        body: JSON.stringify({
          originId: opts.originId,
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getRateSheetDocumentV2: (opts, documentId, tokens) =>
    createFetch(
      `${getApiUrl()}/ratesheet/${documentId}`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: "Bearer",
      },
      tokens,
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  logEvent: async (payload) =>
    createFetch(
      `${getApiUrl()}/event`,
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify({
          ...payload,
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getMe: (opts) =>
    createFetch(
      "/iam/v1/users/me",
      opts,
      {},
      {
        method: "GET",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      },
      [apiResponseHandler()]
    ),
  getEncompassSettingsV1: async () =>
    createFetch(
      `${getApiUrl()}/encompassSettings`,
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getLoanLimitsV1: (opts, loanData) =>
    createFetch(
      `${getApiUrl()}/lookups/loanlimits/`,
      {
        headers: { "X-Elli-PAT": opts.patToken },
        body: JSON.stringify({
          originId: opts.originId,
          state: loanData.property.state,
          county: loanData.property.county,
          zip: loanData.property.zip,
          effectiveDate: loanData.relockRequest.effectiveDate,
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getLoanLimitsV2: (opts, loanId, tokens) =>
    createFetch(
      `${getApiUrl()}/lookups/loans/${loanId}/loanlimits/`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: "Bearer",
      },
      tokens,
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getTransactionDetails: (transactionId) =>
    createFetch(
      `${getApiUrl()}/transaction/${transactionId}`,
      {},
      {},
      {
        method: "GET",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)]
    ),
  getClientRole: async () =>
    createFetch(
      `${getApiUrl()}/user/clientrole`,
      {
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getClientRoleV2: async () =>
    createFetch(
      `${getApiUrl()}/user/clientRole/`,
      {
        customToken: { value: await Session.get(JWT_TOKEN) },
        tokenType: "Bearer",
      },
      {},
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
  getAllLookups: (opts, tokens) =>
    createFetch(
      `${getApiUrl()}/lookups/all/`,
      {
        ...opts,
        effectiveDate: `${moment()
          .tz("America/New_York")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}`,
      },
      tokens,
      {
        method: "GET",
      },
      [apiResponseHandler(401)],
      true,
      true
    ),
};
